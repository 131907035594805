<template>
  <div>
    <!--
    <div :class="'mobileNavBar ' + deviceType">

      <div @click="routeBack('/', false)" class="goBack" style="">
        <span  v-if="deviceType == 'ios'">
          <span style="height: 22px;position: absolute; margin-top: -1px; line-height: 22px; color: #007AFF; font-size: 17px; margin-left: 30px; font-weight: 400;">Geri</span>
          <img style="height: 22px; position: absolute;left: 12px; opacity: 1; line-height: 22px;"  :src="$root.CDN_SITE + 'icon/ios_back_laci.svg'">
        </span>
        <span  v-else>
           <img style="height: 18px; padding-left: 16px; line-height: 18px; " :src="$root.CDN_SITE + 'icon/ic_arrow_back.svg'">
        </span>
      </div>
      <div class="title" style=" color: #2d3640;">
        {{ name }}
      </div>
      <div class="options" ></div>
    </div>


    <div :class="'underAppBar' + deviceType"></div>
-->
    <div style="display: flex; padding-top:44px; width: 100%; margin: 0 auto; background-color: white!important;">
      <div style="width: 100%!important;" :style="{ 'min-height': `${windowHeight - 110}px` }">
        <div class="" style="background-color: white;width: 100%; ">
          <div v-html="content" style="width: 100%!important; line-height: 1.8"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import mixin from './mixin';


  export default {
    name: "src-pages-body-staticContents-staticpages-v1_mobile",
    mixins: [mixin]
  }
</script>

<style scoped>

</style>
